import React, { Component } from "react";
import { Container } from "react-bootstrap";
// import Dushanbe from './Dushanbe';
import "./Tarrifs.css";
import Dushanbe from "./Dushanbe/Dushanbe";

class Tarrifs extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedCity: "dushanbe" }; // Начальное состояние
    this.handleCityChange = this.handleCityChange.bind(this); // Привязываем метод к контексту класса
  }

  // Обработчик изменений для <select>
  handleCityChange(event) {
    const newCity = event.target.value;
    this.setState({ selectedCity: newCity });
    console.log(newCity); // Вывод выбранного города в консоль
  }
  renderCityInfo() {
    switch (this.state.selectedCity) {
      case "dushanbe":
        return <Dushanbe />;
      case "khujand":
        return <p>Худжанд - второй по величине город в Таджикистане.</p>;
      case "kulyab":
        return <p>Куляб - известен своими прекрасными парками.</p>;
      case "kurgantube":
        return <p>Курган-Тюбе - живописный город на юге страны.</p>;
      default:
        return <p>Пожалуйста, выберите город.</p>;
    }
  }

  render() {
    return (
      <>
        <Container>
          <div className="selectCity sm:flex sm:px-4 sm:flex-col">
            <p className="text-[30px] font-[900] sm:text-[20px] sm:font-[600]">
              Домашний интернет для{" "}
            </p>
            <div>
              <select
                value={this.state.selectedCity}
                style={{ outline: "none" }}
                onChange={this.handleCityChange}
              >
                <option value="dushanbe">г.Душанбе</option>
                <option value="khujand">г.Худжанд</option>
                <option value="kulyab">г.Куляб</option>
                <option value="kurgantube">г.Курган-Тюбе</option>
              </select>
            </div>
          </div>
          {this.renderCityInfo()}
        </Container>
      </>
    );
  }
}

export default Tarrifs;
