import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import axios from "axios";

import { URL } from "../url";
const AllNews = () => {
  const [tarifs, setTarifs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTarifs = async () => {
      try {
        const response = await axios.get(`${URL}/api/news`);
        // console.log("Полученные данные:", response.data);
        setTarifs(response.data);
      } catch (error) {
        setError(error.response ? error.response.data : error.message);
        // console.error("Ошибка при получении данных", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTarifs();
  }, []);

  if (loading) {
    return <div>Загрузка новостей...</div>;
  }

  if (error) {
    return <div>Ошибка: {error}</div>;
  }

  return (
    <div className="News">
      <Container>
        <div className="title">
          <div>
            <h3>Наши новости</h3>
          </div>
          {/* <div><a href='/news'><h4>Все статьи</h4></a></div> */}
        </div>
        <div className="grid grid-cols-3 sm:px-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 sm:grid-cols-1 mb-[30px] gap-[20px] pt-[20px]">
          {tarifs.map((item) => (
            <Card className="myCard rounded-[20px]" key={item.id}>
              <Card.Img
                className="img"
                variant="top"
                src={`${URL}${item.image}`}
                alt={item.title}
              />
              <Card.Body>
                <span className="date">{item.date}</span>
                <Card.Title className="cardTitle">{item.title}</Card.Title>
                <Card.Text className="cardText">{item.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default AllNews;
