/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Dushanbe.css";
import sotSet from "../../../images/sotSet.png";
import SotSet from "../../../images/sotSet1.png";
import SotSS from "../../../images/sotSS.png";
import cyberS from "../../../images/cyberS.png";
import cyberM from "../../../images/cyberM.png";
import cyberL from "../../../images/cyberL.png";
import cyberXL from "../../../images/cyberXL.png";
import cyberXXL from "../../../images/cyberXXL.png";
import Vector from "../../../images/Vector.png";

import { URL } from "../../../url";

const Dushanbe = () => {
  const navigate = useNavigate();
  const [tarifs, setTarifs] = useState([]);

  useEffect(() => {
    const fetchTarifs = async () => {
      try {
        const response = await axios.get(`${URL}/api/home-tarif`);
        setTarifs(response.data);
      } catch (error) {
        console.error(
          "Ошибка при получении данных",
          error.response ? error.response.data : error.message
        );
      }
    };
    fetchTarifs();
  }, []);
  const handleSelectTariff = (tariffName) => {
    navigate(`/connection/${tariffName}`);
  };
  return (
    <>
      <div>
        <div className="grid grid-cols-5 sm:px-3 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-1 mb-[30px] gap-[20px] pt-[20px]">
          {tarifs?.map((el) => {
            return (
              <div
                style={{
                  border:
                    el.id === 4 ? "2px solid #495AFF" : "1px solid #b3b2b2",
                }}
                className="bg-white flex flex-col gap-[5px] rounded-[20px] p-[15px] transform transition-transform duration-300 hover:scale-105"
              >
                <p className="text-[25px] font-[700]">{el.name}</p>
                <div className="flex flex-col justify-center ml-[30px]">
                  <img
                    src={`${URL}${el.image}`}
                    className="h-[120px] sm:hidden w-[120px]"
                    alt="he"
                  />
                </div>
                <div className="flex mt-[15px] items-start gap-[15px]">
                  <img
                    className="w-[15px] h-[15px] object-contain"
                    src={Vector}
                    alt="vector"
                  />
                  <div className="flex flex-col leading-4 gap-[0px] mt-[-5px]">
                    <p>Скорость</p>
                    <p className="font-[595] flex gap-[5px] text-[22px] ">
                      {el.speed} <p className="font-[600]"> МБить/с</p>
                    </p>
                  </div>
                </div>
                <div className="flex mt-[15px] items-start gap-[15px]">
                  <img
                    className="w-[15px] h-[15px] object-contain"
                    src={Vector}
                    alt="vector"
                  />
                  <div className="flex flex-col leading-4 gap-[0px] mt-[-5px]">
                    <p>Интернет Трафик</p>
                    <p className="font-[600] text-[22px]">{el.traffic}</p>
                  </div>
                </div>
                <div className="flex mt-[15px] items-start gap-[15px]">
                  <img
                    className="w-[15px] h-[15px] object-contain"
                    src={Vector}
                    alt="vector"
                  />
                  <div className="flex flex-col leading-4 gap-[0px] mt-[-5px]">
                    <p>Скорость доступа на</p>
                    <img src={SotSS} alt="h" />
                    <p className="font-[595] flex gap-[5px] text-[22px] pt-[20px]">
                      {el.accessSpeed}
                      <p className="font-[595]">Мбит/с</p>
                    </p>
                  </div>
                </div>
                <div className="flex items-end gap-[5px] justify-center ml-[-10px]">
                  <p className="font-[700] text-[23px]">{el.price}</p>
                  <p>сом/месяц</p>
                </div>
                <div className="grid grid-cols-1 gap-[10px]">
                  <Button
                    onClick={() => handleSelectTariff(`${el.name}`)}
                    variant="primary"
                  >
                    Выбрать
                  </Button>
                  <a
                    href="/connection"
                    className="no-underline text-center text-[black]"
                  >
                    Подробное
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Dushanbe;
