import React, { Component } from "react";
import axios from "axios";

class Myip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ip: "",
    };
  }

  componentDidMount() {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        this.setState({ ip: response.data.ip });
      })
      .catch((error) => console.log("Ошибка:", error));
  }

  render() {
    const { ip } = this.state;
    return (
      <div className="">
        {ip ? (
          <span className="youIp text-[14px]">Ваш IP: {ip}</span>
        ) : (
          <span className="youIp">Загрузка...</span>
        )}
      </div>
    );
  }
}

export default Myip;
