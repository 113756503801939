import React, { useState, useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import CryptoJS from "crypto-js";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";

const MapComponent = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const secretKey = process.env.REACT_APP_API_KEY;
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [mapClickCoordinates, setMapClickCoordinates] = useState(null);
  const [modalCoordinates, setModalCoordinates] = useState({
    lng: "",
    lat: "",
  });
  const [cities, setCities] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [prefilledAddress, setPrefilledAddress] = useState("");
  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const handleClickOpen = () => {
    if (responseData && responseData.sms_message) {
      const addressPart = responseData.sms_message.split("(")[0].trim();
      setPrefilledAddress(addressPart);
      setOpen(true);
      setFormErrors({});
      setIsSubmitting(false);
      setSubmitSuccess(false);
      setShouldOpenModal(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [house, setHouse] = useState("");
  const [apartment, setApartment] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const [responseColor, setResponseColor] = useState("");
  const [smsMessage, setSmsMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const testGetCities = async () => {
    const path = "/api/get/map/city";
    const method = "GET";
    const timestamp = Math.floor(Date.now() / 1000).toString();
    const clientId = "babilon-web";
    const normalizedPath = path.replace(/^\//, "");
    const normalizedMethod = method.toUpperCase();

    const dataToSign = [
      timestamp,
      clientId,
      normalizedMethod,
      normalizedPath,
      "", // пустая строка для GET запроса
    ].join("|");

    const hash = CryptoJS.HmacSHA256(dataToSign, secretKey).toString(
      CryptoJS.enc.Hex
    );

    const headers = {
      "X-Timestamp": timestamp,
      "X-Client-ID": clientId,
      "X-Api-Version": "1.0",
      "X-Hash": hash,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios({
        url: baseUrl + path,
        method: method,
        headers: headers,
      });

      setCities(response.data || []);
      console.log("Response data:", response.data);
    } catch (error) {
      if (error.response) {
        console.error("Server error:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    testGetCities();
    const mapInstance = L.map("map").setView([38.566885, 68.774133], 13);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      mapInstance
    );
    setMap(mapInstance);

    mapInstance.on("click", (e) => {
      const { lat, lng } = e.latlng;

      setMapClickCoordinates({ lat, lng });
      setCoordinates({ lat, lng });

      console.log("Map click coordinates:", { lat, lng });
      if (marker) {
        marker.setLatLng([lat, lng]);
      } else {
        const newMarker = L.marker([lat, lng], {
          icon: L.icon({
            iconUrl:
              "https://upload.wikimedia.org/wikipedia/commons/6/6f/Green_arrow_icon.svg",
            iconSize: [30, 30],
            iconAnchor: [15, 30],
          }),
        }).addTo(mapInstance);
        setMarker(newMarker);
      }

      handleCheckHouse(lat, lng);
    });

    return () => mapInstance.remove();
  }, [marker]);

  const handleCityChange = (e) => {
    const selectedCity = cities.find((c) => c.name === e.target.value);
    if (selectedCity) {
      setCity(selectedCity.name);
      const coords = selectedCity.coords;
      map.setView([parseFloat(coords[0]), parseFloat(coords[1])], 13);

      if (marker) {
        marker.setLatLng([parseFloat(coords[0]), parseFloat(coords[1])]);
      } else {
        const newMarker = L.marker([
          parseFloat(coords[0]),
          parseFloat(coords[1]),
        ]).addTo(map);
        setMarker(newMarker);
      }
    }
  };

  const handleAddressChange = () => {
    const address = `${street}, ${house}, ${apartment}, ${city}`;
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
      address
    )}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data[0]) {
          const { lat, lon } = data[0];
          map.setView([lat, lon], 13);
          if (marker) {
            marker.setLatLng([lat, lon]);
          } else {
            const newMarker = L.marker([lat, lon]).addTo(map);
            setMarker(newMarker);
          }
        }
      });
  };

  const handleCheckHouse = async (lat, lng) => {
    if (lat && lng) {
      const path = `/api/check-house`;
      const method = "GET";
      const timestamp = Math.floor(Date.now() / 1000).toString();
      const clientId = "babilon-web";
      const normalizedPath = path.replace(/^\//, "");
      const normalizedMethod = method.toUpperCase();

      const dataToSign = [
        timestamp,
        clientId,
        normalizedMethod,
        normalizedPath,
        "",
      ].join("|");

      console.log("Data to sign last:", dataToSign);

      const hash = CryptoJS.HmacSHA256(dataToSign, secretKey).toString(
        CryptoJS.enc.Hex
      );
      console.log("Generated hash last:", hash);

      const headers = {
        "X-Timestamp": timestamp,
        "X-Client-ID": clientId,
        "X-Api-Version": "1.0",
        "X-Hash": hash,
        "Content-Type": "multipart/form-data",
      };

      try {
        const response = await axios({
          url: baseUrl + path,
          method: method,
          headers: headers,
          params: {
            longitude: lng,
            latitude: lat,
          },
        });

        const data = response.data;
        console.log("House Check Response:", response.data);
        setResponseData(response.data);
        setModalCoordinates({ lat, lng });

        if (data.message) {
          setResponseMessage(data.message);
          setSmsMessage(data.sms_message.split("(")[0]);
          setStatusMessage(
            data.message === "House Exists"
              ? "Ваш дом подключен"
              : "Ваш дом не подключен"
          );
          setResponseColor(
            data.message === "House Exists" ? "#007000" : "#d2222d"
          );
        } else {
          alert("Ошибка: неизвестный ответ от сервера.");
        }
      } catch (error) {
        console.error("Error checking house:", error);
      }
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!responseData) {
      console.error("No response data available");
      return;
    }
    const path = `/api/add/map-request`;
    const method = "POST";
    const timestamp = Math.floor(Date.now() / 1000).toString();
    const clientId = "babilon-web";
    const normalizedPath = path.replace(/^\//, "");
    const normalizedMethod = method.toUpperCase();

    const cityName = responseData.sms_message.split(",")[0].trim();
    const selectedCity = cities.find((city) => city.name === cityName);

    const form = event.currentTarget;
    const formData = new FormData(form);
    console.log("Name:", formData.get("name"));
    console.log("Contact:", formData.get("contact"));
    console.log("Description:", formData.get("description"));

    console.log("form: ", form);

    const payload = {
      f: {
        lat: modalCoordinates.lat,
        lng: modalCoordinates.lng,
        city_id: selectedCity ? selectedCity.id : null,
        ctype: 1,
        address: prefilledAddress,
        map_coverage_id: responseData.id,
        name: formData.get("name"),
        contact: formData.get("contact"),
        description: formData.get("description"),
      },
    };

    const payloadString = JSON.stringify(payload);

    const dataToSign = [
      timestamp,
      clientId,
      normalizedMethod,
      normalizedPath,
      payloadString,
    ].join("|");

    const hash = CryptoJS.HmacSHA256(dataToSign, secretKey).toString(
      CryptoJS.enc.Hex
    );

    const headers = {
      "X-Timestamp": timestamp,
      "X-Client-ID": clientId,
      "X-Api-Version": "1.0",
      "X-Hash": hash,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios({
        url: baseUrl + path,
        method: method,
        headers: headers,
        data: payload,
      });

      if (response.status >= 200 && response.status < 300) {
        handleClose();
        toast.success("Ваша заявка отправлено", {
          duration: 4000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error(
        "Submission Error:",
        error.response ? error.response.data : error
      );
      toast.error("Произошла ошибка при отправке формы", {
        duration: 4000,
        position: "top-right",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="pl-[7%] pt-[5%] pb-[5%] pr-[7%] bg-[#25AAF8] mx-auto">
      <Toaster />
      <div className="flex sm:flex-col sm:items-start pb-[30px] justify-between items-center">
        <p className="text-[30px] font-[600] sm:text-[18px] text-white">
          Проверьте возможность подключения
        </p>
        <div>
          <div className="w-full pb-[15px] md:pb-[10px]">
            <select
              className="w-full rounded-[5px] py-3 pl-[15px] pr-[25px]"
              value={city}
              onChange={handleCityChange}
            >
              <option value="">Выберите город</option>
              {cities.map((cityItem) => (
                <option key={cityItem.id} value={cityItem.name}>
                  {cityItem.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {responseMessage && (
        <div
          className={`text-[16px] font-[500] pb-[15px]`}
          style={{ color: responseColor }}
        >
          {responseMessage === "House Exists" ? (
            <>
              <div className="pl-[10px] flex items-center justify-between rounded-md bg-[#8ecae6] pr-[10px] pt-[20px] pb-[20px]">
                <div>
                  <p className="text-[24px] font-[600]">Ваш дом подключен</p>
                  <p>{smsMessage}</p>
                </div>
                <div>
                  <Dialog open={open} onClose={handleClose}>
                    <form onSubmit={handleFormSubmit}>
                      <DialogTitle>Оставить заявку на подключение</DialogTitle>
                      <DialogContent>
                        {submitSuccess ? (
                          <DialogContentText color="primary">
                            Ваша заявка успешно отправлена! Мы свяжемся с вами в
                            ближайшее время.
                          </DialogContentText>
                        ) : (
                          <>
                            <TextField
                              autoFocus
                              required
                              margin="dense"
                              name="name"
                              label="Ваше имя"
                              type="text"
                              fullWidth
                              variant="standard"
                              value={formData.name}
                              onChange={handleInputChange}
                            />
                            <TextField
                              required
                              margin="dense"
                              name="address"
                              label="Адрес"
                              type="text"
                              fullWidth
                              variant="standard"
                              value={prefilledAddress}
                              // InputProps={{
                              //   readOnly: true,
                              // }}
                            />
                            <TextField
                              required
                              margin="dense"
                              name="contact"
                              label="Ваши контактные данные"
                              type="tel"
                              fullWidth
                              variant="standard"
                              value={formData.contact}
                              onChange={handleInputChange}
                            />
                            <TextField
                              margin="dense"
                              name="description"
                              label="Оставьте комментарий"
                              type="text"
                              fullWidth
                              variant="standard"
                              value={formData.description}
                              onChange={handleInputChange}
                            />
                          </>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} disabled={isSubmitting}>
                          Отмена
                        </Button>
                        {!submitSuccess && (
                          <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Отправка..." : "Отправить"}
                          </Button>
                        )}
                      </DialogActions>
                    </form>
                  </Dialog>
                  <button
                    onClick={handleClickOpen}
                    className="p-[10px] rounded-md text-white bg-[#023047]"
                  >
                    Подключиться
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <p>Ваш дом не подключен</p>
            </>
          )}
        </div>
      )}
      <div
        id="map"
        style={{ width: "100%", height: "400px", borderRadius: "15px" }}
      ></div>
    </div>
  );
};

export default MapComponent;
