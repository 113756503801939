import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../images/logo.png";
import login from "../images/Login.png";
import "./Header.css";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "@szhsin/react-menu/dist/index.css";

import {
  ControlledMenu,
  MenuItem,
  useHover,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";

function NavScrollExample() {
  const location = useLocation();
  const isBusinessMenuVisible = location.pathname.includes("/business");

  //   Доп.услуги
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  const refInternet = useRef(null);
  const [isOpenInternet, setOpenInternet] = useState(false);
  const { anchorProps: anchorPropsInternet, hoverProps: hoverPropsInternet } =
    useHover(isOpenInternet, setOpenInternet);

  return (
    <Navbar collapseOnSelect expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Brand href="/home">
          <img
            src={logo}
            height="50"
            width="128"
            className="d-inline-block align-top"
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "370px" }}
            navbarScroll
          >
            {isBusinessMenuVisible ? (
              <>
                <Nav.Link ref={ref} {...anchorProps}>
                  Для бизнеса <ArrowDropDownIcon />
                </Nav.Link>
                <ControlledMenu
                  {...hoverProps}
                  state={isOpen ? "open" : "closed"}
                  anchorRef={ref}
                  onClose={() => setOpen(false)}
                >
                  <p className="ml-[15px] mt-[5px] text-[grey]">Интернет</p>
                  <MenuItem
                    className="no-underline mt-[-15px] text-[black]"
                    href="/business/tariffs"
                  >
                    Тарифы
                  </MenuItem>
                  <MenuItem className="no-underline text-[black]">
                    Оборудование
                  </MenuItem>
                </ControlledMenu>
              </>
            ) : (
              <>
                <Nav.Link ref={refInternet} {...anchorPropsInternet}>
                  <div className="flex">
                    <p className="text-[18px]">Интернет</p>
                    <div className="pt-[2px]">
                      <ArrowDropDownIcon />
                    </div>
                  </div>
                </Nav.Link>
                <ControlledMenu
                  {...hoverPropsInternet}
                  state={isOpenInternet ? "open" : "closed"}
                  anchorRef={refInternet}
                  onClose={() => setOpenInternet(false)}
                >
                  <SubMenu label="Домашный интернет">
                    <MenuItem>
                      <NavDropdown.Item href="/Homeinternet">
                        Тарифы
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className="text" href="#">
                        Оборудование
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className="text" href="#action5">
                        Годовые Пакеты
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className="text" href="#action5">
                        Турбо скорость
                      </NavDropdown.Item>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu label="Технология WiMAX">
                    <MenuItem>
                      <NavDropdown.Item href="/wimax">Тарифы</NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className="text" href=".wimax#12">
                        Оборудование
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className="text" href="#action5">
                        Пакеты
                      </NavDropdown.Item>
                    </MenuItem>
                  </SubMenu>

                  <SubMenu label="Технология LTE">
                    <MenuItem>
                      <NavDropdown.Item href="/lte">Тарифы</NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className="text" href="/lte#13">
                        Оборудование
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className="text" href="#action5">
                        Годовые Пакеты
                      </NavDropdown.Item>
                    </MenuItem>
                    <MenuItem>
                      <NavDropdown.Item className="text" href="#action5">
                        Турбо скорость
                      </NavDropdown.Item>
                    </MenuItem>
                  </SubMenu>
                </ControlledMenu>
              </>
            )}
            {isBusinessMenuVisible ? (
              <>
                <Nav.Link href="/business/ngn">Телефония</Nav.Link>
                <Nav.Link href="/business/vps">Виртуальный сервер</Nav.Link>
                <Nav.Link href="/business/domain">Домен</Nav.Link>
                <Nav.Link href="/business/hosting">Хостинг</Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link href="/tv">
                  <p className="text-[18px]">Телевидение</p>
                </Nav.Link>
                <Nav.Link href="/ngn">
                  <p className="text-[18px]">Телефония</p>
                </Nav.Link>

                <Nav.Link ref={ref} {...anchorProps}>
                  <div className="flex">
                    <p className="text-[18px]"> Доп.услуги</p>
                    <div className="pt-[2px]">
                      <ArrowDropDownIcon />
                    </div>
                  </div>
                </Nav.Link>
                <ControlledMenu
                  {...hoverProps}
                  state={isOpen ? "open" : "closed"}
                  anchorRef={ref}
                  onClose={() => setOpen(false)}
                >
                  <MenuItem
                    className="no-underline text-[black]"
                    href="/domain"
                  >
                    Домен
                  </MenuItem>
                  <MenuItem
                    className="no-underline text-[black]"
                    href="/hosting"
                  >
                    Хостинг
                  </MenuItem>
                  <MenuItem className="no-underline text-[black]" href="/vps">
                    Виртуальный сервер VPS
                  </MenuItem>
                </ControlledMenu>
                <Nav.Link href="/promotion">
                  <p className="text-[18px]">Акции</p>
                </Nav.Link>
              </>
            )}
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
          </Form>
          <div className="flex items-center">
            <Nav.Link href="https://arm.babilon-t.com" target="_blank">
              <p className="underline pt-[10px] pl-[10px] text-[18px]">Личный кабинет</p>
            </Nav.Link>
            <img
              src={login}
              height="24.68"
              width="24"
              className="ml-[15px]"
              alt="logo"
            />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;

// Write for my header the adaptive for
